<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title class="elevation-1" v-if="user.type=='OmniStore'">
            Edit Omni Store
          </v-card-title>
          <v-card-title class="elevation-1" v-if="user.type=='Seller'">
            Edit Seller
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="profileForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="firstname"
                      label="First Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="lastname"
                      label="Last Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="ownername"
                      label="Owner Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="username"
                      label="Username"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="accounttype"
                      label="Account Type"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="country"
                      label="Country"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="phoneRules"
                      v-model="phone"
                      label="Whatsapp Number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="whatsapp"
                      label="Whatsapp Number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="emailRules"
                      v-model="email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="organization"
                      label="Organization"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="district"
                      label="District"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="locality"
                      label="Locality"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="city" label="City"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="streetAddress"
                      label="Street Address"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="pinRules"
                      v-model="pincode"
                      label="Pincode"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="bankname"
                      label="Bank Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="accountholder"
                      label="Account Holder"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="accountnumber"
                      label="Account Number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="branch"
                      label="Branch"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ifsccode"
                      label="IFSC Code"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="gstid" label="GST ID"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="commisionPercentage"
                      type="number"
                      label="Commision Percentage"
                      :rules="commisionPercentageRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row py-2>
                  <v-col cols="12">Social media links</v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      ref="email"
                      color="#FF1313"
                      label="Facebook URL"
                      dense
                      v-model="facebook"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      ref="email"
                      color="#FF1313"
                      label="Instagram URL"
                      dense
                      v-model="instagram"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" style="color: black; font-weight: bold"
                    >Shop Order</v-col
                  >
                  <v-col cols="12" md="4">
                    <v-text-field
                      ref="email"
                      color="#FF1313"
                      label="New Products"
                      dense
                      v-model="newProductsOrder"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      ref="email"
                      color="#FF1313"
                      label="Offers"
                      dense
                      v-model="offersOrder"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      ref="email"
                      color="#FF1313"
                      label="Gallery"
                      dense
                      v-model="galleryOrder"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-row class="justify-center">
                    <v-col cols="10">
                      <v-img
                        v-if="user.image && !imagePreview"
                        :src="baseURL + user.image"
                        width="200px"
                        height="150px"
                        style="align-center"
                        contain
                      />
                      <img
                        v-if="imagePreview"
                        :src="imagePreview"
                        v-show="showPreview"
                        width="200px"
                        height="150px"
                        class="align-center"
                        contain
                      />
                    </v-col>

                    <v-row class="justify-center">
                      <v-col cols="10">
                        <v-dialog v-model="changeImageDialog" max-width="800px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined v-on="on" v-bind="attrs">
                              Update Profile Picture
                            </v-btn>
                          </template>

                          <v-card>
                            <v-row class="py-5">
                              <v-col cols="12">
                                <ImageComp
                                  :singleImage="imagePreview"
                                  @stepper="winStepper"
                                  :height="'540'"
                                  :width="'540'"
                                  :heading="'Upload  Image (540 X 540)'"
                                  :componentType="'image'"
                                />
                                <!-- (1195 X 804) -->
                              </v-col>
                              <br />
                              <v-col class="text-center" cols="12">
                                <v-btn
                                  :disabled="!file"
                                  color="green"
                                  @click="uploadImage"
                                >
                                  <span style="color: white"> submit </span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-dialog>
                        <!-- <v-btn outlined @click="$refs.showImage.click()"
                          >Update Profile Picture
                          <v-item-group>
                            <input
                              v-show="false"
                              label="Update Profile Picture"
                              ref="showImage"
                              id="file"
                              type="file"
                              style="display: none"
                              @change="showImage"
                            />
                          </v-item-group>
                        </v-btn> -->
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row class="justify-center">
                    <v-col cols="10">
                      <v-img
                        v-if="seller.coverimage && !coverPreview"
                        :src="baseURL + seller.coverimage"
                        width="200px"
                        height="150px"
                        style="align-center"
                        contain
                      />
                      <img
                        v-if="coverPreview"
                        :src="coverPreview"
                        v-show="showcoverPreview"
                        :height="2"
                        :width="1"
                        class="align-center"
                        contain
                      />
                    </v-col>
                    <v-row class="justify-center">
                      <v-col cols="10">
                        <v-dialog
                          v-model="changeCoverImageDialog"
                          max-width="800px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined v-on="on" v-bind="attrs">
                              Update Cover Image
                            </v-btn>
                          </template>
                          <v-card>
                            <v-row class="py-5">
                              <v-col cols="12">
                                <ImageComp
                                  :singleImage="coverPreview"
                                  @stepper="winStepper"
                                  :height="'600'"
                                  :width="'2500'"
                                  :heading="'Upload Cover Image (2500 X 600)'"
                                  :componentType="'coverImage'"
                                />
                              </v-col>
                              <br />
                              <v-col class="text-center" cols="12">
                                <v-btn
                                  :disabled="!coverFile"
                                  color="green"
                                  @click="uploadCover"
                                >
                                  <span style="color: white"> submit </span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-dialog>
                        <!-- <v-btn outlined @click="$refs.showImage.click()"
                          >Update Profile Picture
                          <v-item-group>
                            <input
                              v-show="false"
                              label="Update Profile Picture"
                              ref="showImage"
                              id="file"
                              type="file"
                              style="display: none"
                              @change="showImage"
                            />
                          </v-item-group>
                        </v-btn> -->
                      </v-col>
                    </v-row>
                  </v-row>
                </v-row>
                <v-row class="justify-center" style="padding-top: 80px">
                  <v-btn
                    :disabled="!valid"
                    outlined
                    color="red"
                    @click="validate()"
                  >
                    Update
                  </v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";

export default {
  props: ["sellerid"],
  components: {
    ImageComp,
  },
  data() {
    return {
      changeImageDialog: false,
      changeCoverImageDialog: false,
      ServerError: false,
      coverPreview: "",
      showPreview: false,
      showcoverPreview: false,
      bank: [],
      file: null,
      coverFile: null,
      image: "",
      coverimage: "",
      imagePreview: "",
      appLoading: false,
      showsnackbar: false,
      firstname: null,
      facebook: null,
      instagram: null,
      lastname: null,
      ownername: null,
      username: null,
      accounttype: null,
      country: null,
      phone: null,
      whatsapp: null,
      pincode: null,
      email: null,
      district: null,
      locality: null,
      city: null,
      organization: null,
      streetAddress: null,
      bankname: null,
      accountholder: null,
      accountnumber: null,
      branch: null,
      ifsccode: null,
      gstid: null,
      type: null,
      commisionPercentage: null,
      newProductsOrder: "",
      offersOrder: "",
      galleryOrder: "",
      msg: null,
      valid: true,
      user: [],
      seller: [],
      Rules: [(v) => !!v || "Required"],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      emailRules: [
        (v) => !!v || "Email is Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone Number is Required",
        (v) => (v && v.length <= 10) || "Number must be less than 11",
        (v) => (v && v.length >= 10) || "Number must be grater than 10",
        (v) => /^\d{10}$/.test(v) || "Phone must be number",
      ],
      pinRules: [
        (v) => !!v || "Pincode is Required",
        (v) => /^(\d{4}|\d{6})$/.test(v) || "Pincode must be valid",
      ],
      gstRules: [
        (v) => !!v || "GSTID is Required",
        (v) =>
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(v) ||
          "GSTID must be valid",
      ],
      commisionPercentageRules: [
        // (v) => /^(\d{1}|\d{2}).(\d{1}|\d{2})$/.test(v) || "Pincode must be valid",
        (v) => !!v || " Commision Percentage is Required",
      ],
      ifscRules: [
        (v) => !!v || "IFSC Code is Required",
        (v) =>
          /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/.test(v || "IFSC Code must be valid"),
      ],
      accRules: [
        (v) => !!v || "Account Number is Required",
        (v) =>
          /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/.test(v) ||
          "Account Number must be valid",
      ],
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "image") {
        this.file = window_data.selectedFiles;
      } else if (window_data.type == "coverImage") {
        this.coverFile = window_data.selectedFiles;
      }
    },
    validate() {
      if (this.$refs.profileForm.validate()) {
        this.editUser();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/user/info",
        method: "GET",
        params: {
          id: this.sellerid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.seller = this.user.seller;
          this.firstname = this.user.firstname;
          this.lastname = this.user.lastname;
          this.ownername = this.user.seller.ownername;
          this.username = this.user.username;
          this.phone = this.user.phone;
          this.email = this.user.email;
          this.district = this.user.district;
          this.pincode = this.user.pincode;
          this.locality = this.user.locality;
          this.city = this.user.city;
          this.organization = this.user.organization;
          this.streetAddress = this.user.streetAddress;
          this.whatsapp = this.seller.whatsapp;
          this.facebook = this.seller.facebook;
          this.instagram = this.seller.instagram;
          this.type = this.user.type;
          this.accounttype = this.user.accounttype;
          this.country = this.user.country;
          this.bankname = this.user.seller.bankname;
          this.accountholder = this.user.seller.accountholder;
          this.accountnumber = this.user.seller.accountnumber;
          this.branch = this.user.seller.branch;
          this.gstid = this.user.seller.gstid;
          this.commisionPercentage = this.seller.commisionPercentage;
          this.ifsccode = this.user.seller.ifsccode;
          this.newProductsOrder = this.user.seller.newProductsOrder;
          this.offersOrder = this.user.seller.offersOrder;
          this.galleryOrder = this.user.seller.galleryOrder;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editUser() {
      this.appLoading = true;
      var user = {};
      user["firstname"] = this.firstname;
      user["lastname"] = this.lastname;
      user["ownername"] = this.ownername;
      user["username"] = this.username;
      user["phone"] = this.phone;
      user["email"] = this.email;
      user["type"] = this.type;
      user["accounttype"] = this.accounttype;
      user["country"] = this.country;
      user["streetAddress"] = this.streetAddress;
      user["pincode"] = this.pincode;
      user["locality"] = this.locality;
      user["district"] = this.district;
      user["city"] = this.city;
      user["organization"] = this.organization;
      user["whatsapp"] = this.whatsapp;
      user["facebook"] = this.facebook;
      user["instagram"] = this.instagram;
      user["bankname"] = this.bankname;
      user["accountholder"] = this.accountholder;
      user["accountnumber"] = this.accountnumber;
      user["branch"] = this.branch;
      user["gstid"] = this.gstid;
      user["commisionPercentage"] = this.commisionPercentage;
      user["ifsccode"] = this.ifsccode;
      user["newProductsOrder"] = this.newProductsOrder;
      user["offersOrder"] = this.offersOrder;
      user["galleryOrder"] = this.galleryOrder;
      user["tag"] = this.tag;
      user["id"] = this.sellerid;
      axios({
        method: "POST",
        url: "/user/AdminEdit",

        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.imagePreview) {
              this.uploadImage();
            } else if (this.coverPreview) {
              this.uploadCover();
            } else {
              this.getData();
              // this.uploadImage();
              this.$router.go(-1);
            }
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      if (this.file.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showsnackbar = true;
        return;
      }
      this.showPreview = true;
      //reader.readAsDataURL(files[0]);
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    showCover(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.coverPreview = e.target.result;
      };
      if (this.file.size > 1145728) {
        this.msg = "File Should be less than 1MB";
        this.showsnackbar = true;
        return;
      }
      this.showcoverPreview = true;
      //reader.readAsDataURL(files[0]);
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("photos", this.file);
      formData.append("id", this.sellerid);
      axios({
        method: "POST",
        url: "/User/adminUploadImage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;

            this.changeImageDialog = false;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.getData();

            // this.$router.go(-1);
          } else {
            this.appLoading = false;

            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadCover() {
      let formData = new FormData();
      formData.append("id", this.sellerid);
      formData.append("image", this.coverFile);

      axios({
        method: "POST",
        url: "/User/seller/UploadCoverImage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;

            this.changeCoverImageDialog = false;
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            // this.$router.go(-1);
          } else {
            this.appLoading = false;

            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
